export default function auth({ to, next, store }) {
  const userApps = store.getters.userApps;
  const routeAppIds = to.meta.appIds;
  if (store.getters.isLoggedIn) {
    if (routeAppIds.some((i) => userApps.includes(i))) {
      next();
      return;
    } else {
      next("/dashboard");
      return;
    }
  }
  next("/login?redirected_from=" + to.fullPath);
}
